import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { addDrinkToDrinksDb } from '../services/firebase';

export const showSuccess = () => {
    alert('Drink Added to Database!');
}

const AddDrink = () => {
    const [value, setValue] = useState('');
    const [drink, setDrink] = useState('');
    const [error, setError] = useState('');
    const [radioError, setRadioError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const addToDb = (event) => {
        event.preventDefault();

        if(!drink.trim()) {
            setError('Drink Name Required');
            return;
        } else if(value === '') {
            setRadioError(true);
            setHelperText('Drink Category Required');
            return;
        }
        console.log(`Radio selected = ${value}`);
        console.log(`Drink Name =  ${drink}`);
        addDrinkToDrinksDb(drink, value);
    }

    const handleCat = (event) => {
        setValue(event.target.value);
    }

    const handleDrink = (event) => {
        setDrink(event.target.value);
    }

    return (
        <div className="dark-glass">
            <FormControl error={radioError}>
            <TextField 
                required 
                id="drink-name" 
                label="Drink Name" 
                variant="outlined" 
                value={drink} 
                onChange={handleDrink} 
                sx={{mb: '1em'}} 
                autoComplete="off" 
                error={error !== ''}
                helperText={error !== '' ? 'Drink Name Required' : ' '}
            />
            <FormLabel id="drink-category" sx={{textAlign: "start"}}>Category</FormLabel>
            <RadioGroup row 
                aria-labelledby="drink-category" 
                name="category-group"
                value={value}
                onChange={handleCat}
                >
                <Grid container className="mb1">
                    <Grid item xs={4}>
                    <FormControlLabel value="Beer" control={<Radio size="small" />} label="Beer" />
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel value="Liquor" control={<Radio size="small" />} label="Liquor" />
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel value="Wine" control={<Radio size="small" />} label="Wine" />
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel value="Mixed" control={<Radio size="small" />} label="Mixed" />
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel value="Shots" control={<Radio size="small" />} label="Shots" />
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel value="DD" control={<Radio size="small" />} label="DD" />
                    </Grid>
                </Grid>
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
            <Grid container justifyContent="flex-end">
            <Button variant="contained" sx={{bgcolor: 'rgba(42, 65, 109, 0.75)'}} onClick={addToDb} startIcon={<SaveIcon />}>Save</Button>
            </Grid>
            </FormControl>
            
        </div>
    )
}

export default AddDrink;