import '../App.css';
import { signInWithFirebaseUi } from "../services/firebase";
import { React, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { Home } from '@mui/icons-material';

export default function Login() {
    let navigate = useNavigate();

    useEffect(() => {
        signInWithFirebaseUi();
    }, []);
    
    const goHome = () => {
        navigate("/", {replace: true});
    }
    
    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <IconButton sx={{color: 'white'}} onClick={goHome} aria-label="home"><Home /></IconButton>
            </header>
            <div className="page-body">
                <div className="dark-glass" id="firebaseui-auth-container"></div>
                <div id="loader">Loading...</div>
            </div>
        </div>
    )
}