import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { getUserFavorites } from '../services/firebase';
import {ReactComponent as Beer} from '../icons/beer.svg';
import {ReactComponent as Wine} from '../icons/wine.svg';
import { ReactComponent as Mixed } from '../icons/mixed.svg';
import { ReactComponent as Shot } from '../icons/shot.svg';
import { ReactComponent as Liquor } from '../icons/liquor.svg';
import { ReactComponent as DD } from '../icons/dd.svg';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import IconButton  from '@mui/material/IconButton';
import { makeCancelable } from '../services/makeCancelable';
import { Home, DeleteForever } from '@mui/icons-material';
import { deleteFavorite } from '../services/firebase';
import ConfirmDialog from './confirmDialog';

const MyFavorites = () => {
    const [favs, setFavs] = useState();
    const [isLoaded, setisLoaded] = useState();

    let fetchFavorites = null;
    const currentUser = window.localStorage.getItem('UserId');

    useEffect(() => {
        // let fetchFavorites = null;
        const getFavorites = async () => {
            
            if(fetchFavorites) {
                try {
                    await fetchFavorites.promise;

                    return;
                } catch (error) {
                    return;
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
            fetchFavorites = makeCancelable(getUserFavorites(currentUser, (data) => {
                    setFavs(data);
                })
            );

            try {
                const data = await fetchFavorites.promise;
                fetchFavorites = null;
                setisLoaded(true);

                if (isLoaded === true) {
                    if (!fetchFavorites.isCancelled) {
                        setFavs(data);
                    }
                }
                
            } catch (error) {
                if (!fetchFavorites.isCancelled) {
                    fetchFavorites = null;
                    console.log(`Error in Favorites: ${error}`);
                }
            }
        };

        getFavorites();
        
        return () => {
            if (fetchFavorites) {
                fetchFavorites.cancel();
            }
        };
        
    }, []);
    
    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <Link to={"/"}>
                <IconButton sx={{color: 'white'}} aria-label="home"><Home /></IconButton>
                </Link>
            </header>
            <div className="page-body">
            <Zoom in={true} style={{transitionDelay: '500ms'}}>
            <div className="dark-glass">
                <Link to="/favorites" className="favs-title">
                    <StarOutlineOutlinedIcon sx={{mr: 1, width: '0.75em', height: '0.75em'}}/>My Favorites<StarOutlineOutlinedIcon sx={{ml: 1, width: '0.75em', height: '0.75em'}}/>
                </Link>
                {favs ? favs.map((drink) => (
                    drink.id ? 
                    <FavoriteDrink key={drink.id} id={drink.id} name={drink.name} category={drink.category}/> :
                    <FavoriteDrink key={drink.name} name={drink.name} category={drink.category}/>
                    )):(
                    <Stack spacing={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Skeleton variant="text" width="100%" sx={{mr: 1, bgcolor: 'grey.800'}}/>
                            <Skeleton variant="circular" width={30} height={30} sx={{bgcolor: 'grey.800'}}><Avatar /></Skeleton>
                        </Box>
                    </Stack>
                )}
            </div>
            </Zoom>
            </div>
        </div>
    );
}

const handleDelete = async (item, userid) => {
    await deleteFavorite(item, userid);
    window.location.reload(false);
}

const FavoriteDrink = ({id, name, category}) => {
    const drinkItem = {id, name, category};
    const currentUser = window.localStorage.getItem('UserId');
    const [confirmOpen, setConfirmOpen] = useState(false);

    switch(category) {
        case 'Beer':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                    <div>{name}</div>
                    </Link>
                    <Beer className="drink-icon" fill="white" alt="beer"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        case 'Wine':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                        <div>{name}</div>
                    </Link>
                    <Wine className="drink-icon" fill="white" alt="wine"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        case 'Mixed':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                        <div>{name}</div>
                    </Link>
                    <Mixed className="drink-icon" fill="white" alt="mixed"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        case 'Shots':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                        <div>{name}</div>
                    </Link>
                    <Shot className="drink-icon" fill="white" alt="shot"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        case 'Liquor':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                        <div>{name}</div>
                    </Link>
                    <Liquor className="drink-icon" fill="white" alt="liquor"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        case 'DD':
            return (
                <div className="drink-item" id={id}>
                    <Link to="/order" className="drink-item-name" state= {{drinkOrder:{id, name, category}}}>
                        <div>{name}</div>
                    </Link>
                    <DD className="drink-icon" fill="white" alt="no alcohol"/>
                    <IconButton onClick={() => {setConfirmOpen(true)}}>
                        <DeleteForever />
                    </IconButton>
                    <ConfirmDialog title="Delete Favorite" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => {handleDelete(drinkItem, currentUser)}}>
                        Are You sure you want to delete this favorite?
                    </ConfirmDialog>
                </div>
            )
        default:
            return (
                <div className="drink-item">
                    <div>Drink Name</div>
                    <div>Icon</div>
                </div>
            )
    }
}
export default MyFavorites;