import '../App.css';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { AUTH, getUserInfoDoc } from "../services/firebase";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Logout, Home, Edit } from '@mui/icons-material';

const user = window.localStorage.getItem('UserId');

export default function Account() {
    const [isAdmin, setAdmin] = useState();
    new Promise((resolve, reject) => {
        resolve(getUserInfoDoc(user));
    }).then((doc) => {
        if (doc.userData.IsAdmin) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }).catch((err) => {
        console.log(`Error Getting UserInfo: ${err.message}`);
    });

    let navigate = useNavigate();
    const logOut = () => {
        AUTH.signOut().then(() => {
            console.log("User has Signed Out!");
            window.localStorage.removeItem('UserId');
            navigate("/", {replace: true});
        }).catch((err) => {
            console.log(`Error Signing Out: ${err.message}`);
        });
    }

    const goHome = () => {
        navigate("/", {replace: true});
    }

    const manageDrinks = () => {
        navigate("/manage", {replace: true});
    }

    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <IconButton sx={{color: 'white'}} onClick={goHome} aria-label="home"><Home /></IconButton>
            </header>
            <div className="page-body">
                <div className="dark-glass">
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" sx={{bgcolor: 'rgba(42, 65, 109, 0.75)'}} onClick={logOut} startIcon={<Logout />}>Logout</Button>
                        </Grid>
                        <Grid item xs={6}>
                            {isAdmin ? 
                                <Button variant="contained" sx={{bgcolor: 'rgba(42, 65, 109, 0.75)'}} onClick={manageDrinks} startIcon={<Edit />}>Edit Drinks</Button>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}