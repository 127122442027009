import './index.css';
import 'firebaseui/dist/firebaseui.css';
import './services/firebase';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Login from './views/login';
import Account from './views/account';
import DrinkList from './views/drinkList';
import Order from './views/order';
import Manage from './views/manage';
import MyFavorites from './components/myfavorites';
import TOS from './views/tos';
import Privacy from './views/privacy';
import { SnackbarProvider } from 'notistack';


const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/account" element={<Account />} />
      <Route path="/drinklist" element={<DrinkList />}/>
      <Route path="/order" element={<SnackbarProvider><Order /></SnackbarProvider>}/>
      <Route path="/favorites" element={<SnackbarProvider><MyFavorites /></SnackbarProvider>}/>
      <Route path="/manage" element={<SnackbarProvider><Manage /></SnackbarProvider>} />
      <Route path="/tos" element={<TOS />}/>
      <Route path="/privacy" element={<Privacy />}/>
    </Routes>
  </BrowserRouter>, 
  rootElement
);

serviceWorkerRegistration.register();