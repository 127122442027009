import '../App.css';
// import React, { useState } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import { Home } from '@mui/icons-material';
import { SwipeableEdgeDrawer, DisplayCount, DisplayInstructions } from '../components/drawer';
import {ReactComponent as Beer} from '../icons/beer.svg';
import {ReactComponent as Wine} from '../icons/wine.svg';
import { ReactComponent as Mixed } from '../icons/mixed.svg';
import { ReactComponent as Shot } from '../icons/shot.svg';
import { ReactComponent as Liquor } from '../icons/liquor.svg';
import { ReactComponent as DD } from '../icons/dd.svg';

const Order = () => {
    const location = useLocation();
    const { drinkOrder } = location.state;
    // console.log(`location = ${JSON.stringify(location)}`);

    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <Link to={"/"}>
                    <IconButton sx={{color: 'white'}} aria-label="home"><Home /></IconButton>
                </Link>
            </header>
            <div className="page-body">
                <Zoom in={true} style={{transitionDelay: '150ms'}}>
                <div className="dark-glass">
                    <div className="order-card">
                        {/* <div>Drink Id: {drinkOrder.id}</div> */}
                        <div className="order-card-top">
                            <DisplayCount size='2em' />
                            <CategoryIcon category={drinkOrder.category}/>
                        </div>
                        <div className="order-card-middle">
                            <h2>{drinkOrder.name}</h2>
                            <DisplayInstructions />
                        </div>
                        <div className="order-card-bottom">
                            <CategoryIcon category={drinkOrder.category}/>
                            <DisplayCount size='2em' />
                        </div>
                    </div>
                </div>
                </Zoom>
                {/* <div>{drinkOrder.category}</div> */}
            </div>
            <SwipeableEdgeDrawer/>
        </div>
    );
};

const CategoryIcon = ({category}) => {
    switch(category) {
        case 'Beer':
            return (<Beer className="order-icon" fill="white" alt="beer" />)
        case 'Wine': 
            return (<Wine className="order-icon" fill="white" alt="wine" />)
        case 'Mixed': 
            return (<Mixed className="order-icon" fill="white" alt="mixed" />)
        case 'Shots':
            return (<Shot className="order-icon" fill="white" alt="shot" />)
        case 'Liquor':
            return (<Liquor className="order-icon" fill="white" alt="liquor" />)
        case 'DD':
            return (<DD className="order-icon" fill="white" alt="no alcohol" />)
        default: 
            return (<div>Icon</div>)
    }

}


export default Order; 