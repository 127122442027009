import React, {Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { BrowserView } from 'react-device-detect';
import { atom, useAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { addFavorite } from '../services/firebase';

const drawerBleeding = 56;

const Root = styled('div')(() => ({
    position: 'absolute',
    bottom: 60,
    left: 1
}));

const StyledBox = styled(Box)(() => ({
    backgroundColor: 'rgba(17, 25, 40, 0.75)'
}));

const DrawerContent = styled('div')(() => ({
    height: '100%',
    backgroundColor: 'rgba(17, 25, 40, 0.75)'
}));

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: 'white',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const counterAtom = atom(1);
const instructionsAtom = atom('');

const DisplayCount = (prop) => {
    // console.log(`Size = ${prop.size}`);
    const [counterDisplay] = useAtom(counterAtom);
    return (
        <label style={{marginLeft: '.5rem', color: 'white', fontSize: prop.size}} >{counterDisplay}</label>
    )
}

const DisplayInstructions = (prop) => {
    const [instructionsDisplay] = useAtom(instructionsAtom);
    return (
        <label>{instructionsDisplay}</label>
    )
}

const AddToFavsBtn = () => {
    const location = useLocation();
    const { drinkOrder } = location.state;
    const currentUser = window.localStorage.getItem('UserId');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClick = () => {
        if (currentUser) {
            addFavorite(drinkOrder, currentUser);
            enqueueSnackbar('Saved as Favorite', {
                variant: 'info', 
                preventDuplicate: true,
                autoHideDuration: 2500
            });
        } else {
            const loginAction = key => (
                <Fragment>
                    <Button href="/login" variant="text" sx={{color: 'white'}}>Login</Button>
                </Fragment>
            );
            enqueueSnackbar('Login To Save Favorites.', {
                variant: 'error',
                preventDuplicate: true,
                autoHideDuration: 2500,
                loginAction
            });
        }

        
    }

    return (
        <IconButton onClick={handleClick} sx={{color: 'white', typography: 'body2', mx: 1}}><AddIcon /></IconButton>
    )
}

function SwipeableEdgeDrawer(props) {
    const location = useLocation();
    const { drinkOrder } = location.state;
    const [open, setOpen] = React.useState(false);
    const [counter, setCounter] = useAtom(counterAtom);
    const [instructions, setInstructions] =useAtom(instructionsAtom);
    
    
    let incrementCounter = () => setCounter(counter + 1);
    let decrementCounter = () => setCounter(counter - 1);

    const CountIncrement = () => {
        return (<IconButton onClick={() => {incrementCounter()}} sx={{color: 'white', typography: 'body2', ml: 1}}><AddIcon /></IconButton>)
    }

    const CountDecrement = () => {
        return (<IconButton onClick={() => {decrementCounter()}} sx={{color: 'white', typography: 'body2', ml: 1, mr: 2}}><RemoveIcon /></IconButton>)
    }

    const HandleInstructions = (e) => {
        setInstructions(e.target.value);
    }

    if(counter <= 1) {
        decrementCounter = () => setCounter(1);
    } else if (counter >= 5) {
        incrementCounter = () => setCounter(5);
    }

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
    <Root>
        {/* <CssBaseline /> */}
        <Global
        styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(40% - ${drawerBleeding}px)`,
            overflow: 'visible',
            backgroundColor: '#000'
            },
        }}
        />
        <BrowserView>
        <IconButton onClick={toggleDrawer(true)} sx={{color: 'white', typography: 'body2'}}><AddIcon />Add Options</IconButton>
        </BrowserView>
        <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
            keepMounted: true,
        }}
        >
        <StyledBox
            sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            }}
        >
        <Puller />
        <Typography sx={{ p: 2, color: 'white' }}>Order Options</Typography>
        </StyledBox>
        <DrawerContent>
            <Grid container direction="column">
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <div style={{color: 'white', marginLeft: '1em'}}>Qty</div>
                <CountIncrement onClickFunc={incrementCounter} />
                <DisplayCount size='1em'/>
                <CountDecrement onClickFunc={decrementCounter}/>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <TextField
                    value={instructions}
                    onChange={HandleInstructions}
                    fullWidth
                    id="order-instructions"
                    label="Instructions"
                    autoComplete="off"
                    size="small"
                    helperText="Example: On the Rocks"
                    sx={{mx: 2, mt: 2}}
                />
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{mt: 1}}>
                <div style={{color: 'white'}}>Add &nbsp;</div>
                <div style={{color: 'red'}}>{" " + drinkOrder.name}</div>
                <div style={{color: 'white'}}>&nbsp; To Favorites?</div>
                <AddToFavsBtn />
            </Grid>
            </Grid>
        </DrawerContent>
        </SwipeableDrawer>
    </Root>
  );
}


// export default SwipeableEdgeDrawer;

export {SwipeableEdgeDrawer, DisplayCount, DisplayInstructions}