import '../App.css';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Home, Add, Remove } from '@mui/icons-material';
import AddDrink from '../components/addDrink';

// const user = window.localStorage.getItem('UserId');

export default function Manage() {
    const [showAddDrink, setAddDrink] = useState();
    
    let navigate = useNavigate();

    const goHome = () => {
        navigate("/", {replace: true});
    }

    const addDrink = () => {
        setAddDrink(true);
    }

    const removeDrink = () => {
        if (showAddDrink === true) {
            setAddDrink(false);
        }
    }

    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <IconButton sx={{color: 'white'}} onClick={goHome} aria-label="home"><Home /></IconButton>
            </header>
            <div className="page-body">
                <div className="dark-glass mb1">
                    <Grid container direction="row" justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" sx={{bgcolor: 'rgba(42, 65, 109, 0.75)'}} onClick={addDrink} startIcon={<Add />}>Add Drink</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" sx={{bgcolor: 'rgba(42, 65, 109, 0.75)'}} onClick={removeDrink} startIcon={<Remove />}>Remove Drink</Button>
                        </Grid>
                    </Grid>    
                </div>
                {showAddDrink ? <AddDrink /> : null }
            </div>
        </div>
    )
}