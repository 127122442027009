export const makeCancelable = (promise) => {
    let isCancelled = false;
  
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => isCancelled ? reject({isCanceled: true}) : resolve(val),
        error => isCancelled ? reject({isCanceled: true}) : reject(error)
      );
    });
  
    return {
      promise: wrappedPromise,
      cancel() {
        isCancelled = true;
      },
    };
  };