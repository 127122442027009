import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { getRandomDrinks } from '../services/firebase';
import {ReactComponent as Beer} from '../icons/beer.svg';
import {ReactComponent as Wine} from '../icons/wine.svg';
import { ReactComponent as Mixed } from '../icons/mixed.svg';
import { ReactComponent as Shot } from '../icons/shot.svg';
import { ReactComponent as Liquor } from '../icons/liquor.svg';
import { ReactComponent as DD } from '../icons/dd.svg';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { makeCancelable } from '../services/makeCancelable';

const Random = () => {
    const [info, setInfo] = useState();
    const [isLoaded, setisLoaded] = useState();

    let fetchRandomDrinks = null;

    useEffect(() => {
        // let fetchRandomDrinks = null;
        const getRandoms = async () => {
            if(fetchRandomDrinks) {
                try {
                    await fetchRandomDrinks.promise;

                    return;
                } catch (error) {
                    return;
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
            fetchRandomDrinks = makeCancelable(getRandomDrinks((data) => {setInfo(data);}));

            try {
                const data = await fetchRandomDrinks.promise;
                fetchRandomDrinks = null;
                setisLoaded(true);

                if (isLoaded === true) {
                    if (!fetchRandomDrinks.isCancelled) {
                        setInfo(data);
                    }
                }
                
            } catch (error) {
                if (!fetchRandomDrinks.isCancelled) {
                    fetchRandomDrinks = null;
                    console.log(`Error in Random: ${error}`);
                }
            }
        };

        getRandoms();
        
        return () => {
            if (fetchRandomDrinks) {
                fetchRandomDrinks.cancel();
            }
        };
        
    }, []);
    
    return (
        <Zoom in={true} style={{transitionDelay: '500ms'}}>
        <div className="dark-glass">
            <div>5 Random Drinks</div>
            <div className="drink-list">
            {info ? info.map((drink, index) => index < 5 && (
                <RandomDrink key={drink.id} id={drink.id} name={drink.Drink} category={drink.Category}/>
            )):(
                <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Skeleton variant="text" width="100%" sx={{mr: 1, bgcolor: 'grey.800'}}/>
                    <Skeleton variant="circular" width={30} height={30} sx={{bgcolor: 'grey.800'}}><Avatar /></Skeleton>
                </Box>
                </Stack>
            )}
            </div>
         </div>
         </Zoom>
    );
}

const RandomDrink = ({id, name, category}) => {
    switch(category) {
        case 'Beer':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Beer className="drink-icon" fill="white" alt="beer"/>
                </div>
                </Link>
            )
        case 'Wine':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Wine className="drink-icon" fill="white" alt="wine"/>
                </div>
                </Link>
            )
        case 'Mixed':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Mixed className="drink-icon" fill="white" alt="mixed"/>
                </div>
                </Link>
            )
        case 'Shots':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Shot className="drink-icon" fill="white" alt="shot"/>
                </div>
                </Link>
            )
        case 'Liquor':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Liquor className="drink-icon" fill="white" alt="liquor"/>
                </div>
                </Link>
            )
        case 'DD':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <DD className="drink-icon" fill="white" alt="no alcohol"/>
                </div>
                </Link>
            )
        default:
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item">
                <div className="drink-item-name">Drink Name</div>
                    <div>Icon</div>
                </div>
                </Link>
            )
    }
}
export default Random;