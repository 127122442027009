import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { getUserFavorites } from '../services/firebase';
import {ReactComponent as Beer} from '../icons/beer.svg';
import {ReactComponent as Wine} from '../icons/wine.svg';
import { ReactComponent as Mixed } from '../icons/mixed.svg';
import { ReactComponent as Shot } from '../icons/shot.svg';
import { ReactComponent as Liquor } from '../icons/liquor.svg';
import { ReactComponent as DD } from '../icons/dd.svg';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { makeCancelable } from '../services/makeCancelable';

const Favorites = () => {
    const [favs, setFavs] = useState();
    const [isLoaded, setisLoaded] = useState();

    let fetchFavorites = null;
    const currentUser = window.localStorage.getItem('UserId');

    useEffect(() => {
        // let fetchFavorites = null;
        const getFavorites = async () => {
            
            if(fetchFavorites) {
                try {
                    await fetchFavorites.promise;

                    return;
                } catch (error) {
                    return;
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
            fetchFavorites = makeCancelable(getUserFavorites(currentUser, (data) => {
                    setFavs(data);
                })
            );

            try {
                const data = await fetchFavorites.promise;
                fetchFavorites = null;
                setisLoaded(true);

                if (isLoaded === true) {
                    if (!fetchFavorites.isCancelled) {
                        setFavs(data);
                    }
                }
                
            } catch (error) {
                if (!fetchFavorites.isCancelled) {
                    fetchFavorites = null;
                    console.log(`Error in Favorites: ${error}`);
                }
            }
        };

        getFavorites();
        
        return () => {
            if (fetchFavorites) {
                fetchFavorites.cancel();
            }
        };
        
    }, []);
    
    return (
        <Zoom in={true} style={{transitionDelay: '500ms'}}>
        <div className="dark-glass">
            <Link to="/favorites" className="favs-title">
                <StarOutlineOutlinedIcon sx={{mr: 1, width: '0.75em', height: '0.75em'}}/>My Favorites<StarOutlineOutlinedIcon sx={{ml: 1, width: '0.75em', height: '0.75em'}}/>
            </Link>
            {favs ? favs.map((drink, index) => index < 5 && (
                drink.id ? 
                <FavoriteDrink key={drink.id} id={drink.id} name={drink.name} category={drink.category}/> :
                <FavoriteDrink key={drink.name} id={drink.name} name={drink.name} category={drink.category}/>
                )):(
                <Stack spacing={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Skeleton variant="text" width="100%" sx={{mr: 1, bgcolor: 'grey.800'}}/>
                        <Skeleton variant="circular" width={30} height={30} sx={{bgcolor: 'grey.800'}}><Avatar /></Skeleton>
                    </Box>
                </Stack>
            )}
         </div>
         </Zoom>
    );
}

const FavoriteDrink = ({id, name, category}) => {
    switch(category) {
        case 'Beer':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Beer className="drink-icon" fill="white" alt="beer"/>
                </div>
                </Link>
            )
        case 'Wine':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Wine className="drink-icon" fill="white" alt="wine"/>
                </div>
                </Link>
            )
        case 'Mixed':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Mixed className="drink-icon" fill="white" alt="mixed"/>
                </div>
                </Link>
            )
        case 'Shots':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Shot className="drink-icon" fill="white" alt="shot"/>
                </div>
                </Link>
            )
        case 'Liquor':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Liquor className="drink-icon" fill="white" alt="liquor"/>
                </div>
                </Link>
            )
        case 'DD':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <DD className="drink-icon" fill="white" alt="no alcohol"/>
                </div>
                </Link>
            )
        default:
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item">
                    <div className="drink-item-name">Drink Name</div>
                    <div>Icon</div>
                </div>
                </Link>
            )
    }
}
export default Favorites;