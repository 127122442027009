import './App.css';
import { ReactComponent as BEER_I } from './icons/beer.svg';
import { ReactComponent as MIXED_I } from './icons/mixed.svg';
import { ReactComponent as SHOT_I } from './icons/shot.svg';
import { ReactComponent as LIQUOR_I } from './icons/liquor.svg';
import { ReactComponent as WINE_I } from './icons/wine.svg';
import { ReactComponent as DD_I } from './icons/dd.svg';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import { AccountCircle, Login, IosShare, Refresh } from '@mui/icons-material';
import Zoom from '@mui/material/Zoom';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AUTH } from './services/firebase';
import Random from './components/random';
import Favorites from './components/favorites';
import { BrowserView, MobileView, isIOS, isChrome, isSafari } from 'react-device-detect';

const isInstallediOS = (window.navigator.standalone === true);
const isInstalledChrome = (window.matchMedia('(display-mode: standalone)').matches);
const reloadPage = () => {
    window.location.reload(false);
}

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: 'common.white'
  };

export class App extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            currentUser: null,
            userPhoto: null
        }
    }

    unsubscribeFromAuth = null;

    componentDidMount() {
        this.unsubscribeFromAuth = AUTH.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ 
                    currentUser: user,
                    userPhoto: user.photoURL
                });
                window.localStorage.setItem('UserId', user.uid);
            } else {
                this.unsubscribeFromAuth();
            }
            
        })
    }

    render() {
        return (
            <div className="page">
            <header className="page-header">
                {(isInstallediOS || isInstalledChrome) && this.state.userPhoto ?
                <a href="/account"><img className="page-profile" src={this.state.userPhoto} style={{width: '30px', height: '30px'}} alt="user-profile-img" /></a>
                : (isInstallediOS || isInstalledChrome) && this.state.currentUser ?
                <IconButton sx={{color: 'white'}} href="/account" aria-label="account"><AccountCircle /></IconButton> 
                : (isInstallediOS || isInstalledChrome) ?
                <IconButton sx={{color: 'white'}} href="/login" aria-label="login"><Login /></IconButton> 
                :
                <div></div>  
                }
            </header>
            <div className="page-body">
                <div className="home-title">Keep Deck</div>
                {(isInstallediOS || isInstalledChrome) ?
                <MobileView>
                <div className="home-cats">
                    <Link to="/drinklist" state={{category: "Beer"}}>
                    <Zoom in={true} style={{transitionDelay: '50ms'}}>
                    <div className="dark-glass home-cats-card">
                        <BEER_I className="home-cats-card-tr" fill="white" alt="beer" />
                        <div className="home-cats-card-name">Beer</div>
                        <BEER_I className="home-cats-card-bl" fill="white" alt="beer" />
                    </div>
                    </Zoom>
                    </Link>
                    <Link to="/drinklist" state={{category: "Mixed"}}>
                    <Zoom in={true} style={{transitionDelay: '100ms'}}>
                    <div className="dark-glass home-cats-card">
                        <MIXED_I className="home-cats-card-tr" fill="white" alt="mixed" />
                        <div className="home-cats-card-name">Mixed</div>
                        <MIXED_I className="home-cats-card-bl" fill="white" alt="mixed" />
                    </div>
                    </Zoom>
                    </Link>
                    <Link to="/drinklist" state={{category: "Shots"}}>
                    <Zoom in={true} style={{transitionDelay: '150ms'}}>
                    <div className="dark-glass home-cats-card">
                        <SHOT_I className="home-cats-card-tr" fill="white" alt="shot" />
                        <div className="home-cats-card-name">Shots</div>
                        <SHOT_I className="home-cats-card-bl" fill="white" alt="shot" />
                    </div>
                    </Zoom>
                    </Link>
                    <Link to="/drinklist" state={{category: "Liquor"}}>
                    <Zoom in={true} style={{transitionDelay: '200ms'}}>
                    <div className="dark-glass home-cats-card">
                        <LIQUOR_I className="home-cats-card-tr" fill="white" alt="liquor" />
                        <div className="home-cats-card-name">Liquor</div>
                        <LIQUOR_I className="home-cats-card-bl" fill="white" alt="liquor" />
                    </div>
                    </Zoom>
                    </Link>
                    <Link to="/drinklist" state={{category: "Wine"}}>
                    <Zoom in={true} style={{transitionDelay: '250ms'}}>
                    <div className="dark-glass home-cats-card">
                        <WINE_I className="home-cats-card-tr" fill="white" alt="wine" />
                        <div className="home-cats-card-name">Wine</div>
                        <WINE_I className="home-cats-card-bl" fill="white" alt="wine" />
                    </div>
                    </Zoom>
                    </Link>
                    <Link to="/drinklist" state={{category: "DD"}}>
                    <Zoom in={true} style={{transitionDelay: '300ms'}}>
                    <div className="dark-glass home-cats-card">
                        <DD_I className="home-cats-card-tr" fill="white" alt="no-alcohol" />
                        <div className="home-cats-card-name">Non<br />Alcoholic</div>
                        <DD_I className="home-cats-card-bl" fill="white" alt="no-alohol" />
                    </div>
                    </Zoom>
                    </Link>
                </div>
                {this.state.currentUser ? <Favorites /> : <Random />}
                {isIOS ? 
                <Fab sx={fabStyle} onClick={() => {reloadPage()}}><Refresh /></Fab>
                :
                <div></div>
                }
                </MobileView>
                :
                <div>
                <MobileView>
                <div className="dark-glass">
                    <p>Please Install For the best Experience</p>
                    {isIOS && isChrome ? 
                    <div>Open this page in the Safari Browser.</div>
                    : isIOS && isSafari ?
                    <div>
                        <p>
                            <div className="inline-icon">Tap <IosShare sx={{color: 'white', mx: '0.25em'}} /> then select</div>
                            <div>"Add to Home Screen".</div>
                        </p>
                        <p>Then close this tab and select the icon on your home screen.</p>
                    </div>
                    :
                    <div>Select "Add Keep Deck to the Home screen" below.</div>
                    }
                </div>
                </MobileView>
                </div>
                }
                <BrowserView>
                <div className="dark-glass drink-list">
                    <p>Keep Deck is a mobile first web app that allows you to easily show your drink order to the barkeep (aka bartender) without having to scream over loud noise or taking off your mask in a crowded bar!</p>
                    <p>To use this web app, please open this page on a mobile device.</p>
                </div>
                </BrowserView>
            </div>
        </div>
        )
    }
}

export default App;