import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDoc, getDocs, doc, 
    setDoc, addDoc, query, where, orderBy, updateDoc, arrayUnion, 
    arrayRemove } from 'firebase/firestore';
import { GoogleAuthProvider, FacebookAuthProvider, getAuth, TwitterAuthProvider, onAuthStateChanged } from 'firebase/auth';
import config from '../fbconfig'
import { showSuccess } from '../components/addDrink';

const firebaseui = require('firebaseui');
const fbConfig = config.firebase;
const app = initializeApp(fbConfig);
export const AUTH = getAuth(app);
export const DB = getFirestore(app);
export const UI = new firebaseui.auth.AuthUI(AUTH);


const addUser = async (userInfo) => {
    const profilePic = userInfo.photoURL;
    const displayName = userInfo.displayName;
    const userId = userInfo.uid;
    const email = userInfo.email;
    
    const userDetails = {
        Pic: profilePic,
        Name: displayName,
        Id: userId,
        Email: email
    }
    console.log(`addUser userDetails: ${userDetails}`);
    await setDoc(doc(DB, "UserInfo", userId), userDetails, {merge: true});
}

export const signInWithFirebaseUi = async () => {
    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectURL) {
                const user = authResult.user; //user.displayName, user.email and user.photoURL

                await addUser(user);
            },
            uiShown: function() {
                document.getElementById('loader').style.display = 'none';
            }
        },
        signInSuccessUrl: '/',
        signInOptions: [
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    prompt: 'select_account'
                }
            },
            FacebookAuthProvider.PROVIDER_ID,
            TwitterAuthProvider.PROVIDER_ID
        ],
        tosUrl: 'keepdeck.com/tos',
        privacyPolicyUrl: 'keepdeck.com/privacy'
    }

    UI.start('#firebaseui-auth-container', uiConfig);
}

var Random_IDs = [];

const getRandomId = async () => {
    const db = getFirestore(initializeApp(fbConfig));
    const queryDrinks = await getDocs(collection(db, "drinks"));
    const drinkLength = queryDrinks.size;
    const drinkDocs = queryDrinks.docs.map(doc => {
        var data = doc.data()
        data.id = doc.id;
        return data
    });
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    var randomIndex = getRandomInt(drinkLength);
    var randomId = drinkDocs[randomIndex];

    Random_IDs.push(randomId);

    return randomId;
}

export const getRandomDrinks = async (returnedData) => {
    Array.from({ length: 5 }).forEach(async (_, i) => {
        await getRandomId(i);
        if((i+1) === 5) {
            const data = Random_IDs;
            returnedData(data);
        }
    })
}

export const getDrinks = async (cat, returnedDrinks) => {
    const category = cat;
    const db = getFirestore(initializeApp(fbConfig));
    const drinksRef = collection(db, "drinks");
    // const drinkQuery = query(drinksRef, where("Category", "==", category), orderBy("Drink"), limit(20));
    const drinkQuery = query(drinksRef, where("Category", "==", category), orderBy("Drink"));
    const drinkSnap = await getDocs(drinkQuery);
    const drinkList = [];
    drinkSnap.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        drinkList.push(data);
        if (drinkList.length === drinkSnap.size ) {
            returnedDrinks(drinkList);
        }
    })
}

export const addDrinkToDrinksDb = async (drink, category) => {
    await addDoc(collection(DB, "drinks"), {Drink: drink, Category: category}).then(() => {
        showSuccess();
    });
}

export const getUserInfoDoc = async (userId, returnedDoc) => {
    const userDoc = doc(DB, "UserInfo", userId);
    const userSnap = await getDoc(userDoc);
    if(userSnap.exists()) {
        const userData = userSnap.data();
        // returnedDoc({userData});
        return {userData};
    }
};

export const getUserFavorites = async (userId, returnedFavs) => {
    const userDoc = doc(DB, "UserInfo", userId);
    const userSnap = await getDoc(userDoc);

    if (userSnap.exists()) {
        const userData =  userSnap.data();
        const userFavs = userData.favorites;
        returnedFavs(userFavs);
    }
}

export const addFavorite = async (drink, userId) => {
    const userRef = doc(DB, "UserInfo", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        if (drink.id) {
            await updateDoc(userRef, {favorites: arrayUnion({id: drink.id, name: drink.name, category: drink.category})});
        } else {
            await updateDoc(userRef, {favorites: arrayUnion({name: drink.name, category: drink.category})});
        }
    } else {
        onAuthStateChanged(AUTH, (user) => {
            const profilePic = user.photoURL;
            const displayName = user.displayName;
            const userId = user.uid;
            const email = user.email;
            
            const userDetails = {
                Pic: profilePic,
                Name: displayName,
                Id: userId,
                Email: email,
                favorites: [{
                    id: drink.id,
                    name: drink.name,
                    category: drink.category
                }]
            }

            setDoc(doc(DB, "UserInfo", userId), userDetails);
        })
    }
}

export const deleteFavorite = async (drink, userId) => {
    const userRef = doc(DB, "UserInfo", userId);
    if (drink.id) {
        await updateDoc(userRef, {favorites: arrayRemove({id: drink.id, name: drink.name, category: drink.category})});
    } else {
        await updateDoc(userRef, {favorites: arrayRemove({name: drink.name, category: drink.category})});
    }
    
}