import '../App.css';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getDrinks } from '../services/firebase';
import {ReactComponent as Beer} from '../icons/beer.svg';
import {ReactComponent as Wine} from '../icons/wine.svg';
import { ReactComponent as Mixed } from '../icons/mixed.svg';
import { ReactComponent as Shot } from '../icons/shot.svg';
import { ReactComponent as Liquor } from '../icons/liquor.svg';
import { ReactComponent as DD } from '../icons/dd.svg';
import IconButton from '@mui/material/IconButton';
import { Home } from '@mui/icons-material';
import Zoom from '@mui/material/Zoom';
import TextField  from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const DrinkList= () => {
    const [drinks, setDrinks] = useState();
    const location = useLocation();
    const { category } = location.state;

    useEffect(() => {
        getDrinks(category, (data) =>{
            setDrinks(data);
        });
        
    }, [category]);
    

    return (
        <div className="page">
            <header className="page-header">
                <div className="page-title">Keep Deck</div>
                <Link to={"/"}>
                <IconButton sx={{color: 'white'}} aria-label="home"><Home /></IconButton>
                </Link>
            </header>
            <div className="page-body">
                <Zoom in={true} style={{transitionDelay: '150ms'}}>
                <div className="dark-glass drink-list">
                    <div className="drink-list-search">
                        {drinks ? <DrinkSearch list={drinks} cat={category}/> : <div></div>}
                    </div>
                    <div className="drink-list-content">
                        {drinks ? drinks.map((drink) => (
                            <DrinkItem key={drink.id} id={drink.id} name={drink.Drink} category={drink.Category} />
                        )):<CircularProgress />}
                    </div>
                </div>
                </Zoom>
            </div>
        </div>
    );
}

const DrinkItem = ({id, name, category}) => {
    switch(category) {
        case 'Beer':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Beer className="drink-icon" fill="white" alt="beer"/>
                </div>
                </Link>
            )
        case 'Wine':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Wine className="drink-icon" fill="white" alt="wine"/>
                </div>
                </Link>
            )
        case 'Mixed':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Mixed className="drink-icon" fill="white" alt="mixed"/>
                </div>
                </Link>
            )
        case 'Shots':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Shot className="drink-icon" fill="white" alt="shot"/>
                </div>
                </Link>
            )
        case 'Liquor':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <Liquor className="drink-icon" fill="white" alt="liquor"/>
                </div>
                </Link>
            )
        case 'DD':
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item" id={id}>
                    <div className="drink-item-name">{name}</div>
                    <DD className="drink-icon" fill="white" alt="no alcohol"/>
                </div>
                </Link>
            )
        default:
            return (
                <Link to="/order" state= {{drinkOrder:{id, name, category}}}>
                <div className="drink-item">
                    <div className="drink-item-name">Drink Name</div>
                    <div>Icon</div>
                </div>
                </Link>
            )
    }
 };

 const filter = createFilterOptions();

 const DrinkSearch = ({list, cat}) => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(null);
    const listItems = list.map((item) => {
        return { label: item.Drink, id: item.id }
    })
    // console.log(`listItems = ${JSON.stringify(listItems)}`);

    var searchCat = '';

    if(value && value.label) {
        navigate('/order', {state: {drinkOrder:{id: value.id, name: value.label, category: cat}}});
        // console.log(`Value Selected = ${value.label} in category ${cat} with ID ${value.id}`);
    }
    
    switch(cat) {
        case 'DD': 
            searchCat = 'Non Alcoholic'; 
            break;
        case 'Mixed':
            searchCat = 'Mixed Drink';
            break;
        default: 
            searchCat = cat;
            break;
    }

    return (
        <Autocomplete 
            disablePortal
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        label: newValue,
                        category: cat
                    });
                } else if (newValue && newValue.inputValue) {
                    //Create a new value from the user input
                    setValue({
                        label: newValue.inputValue,
                        category: cat
                    });
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const {inputValue} = params;
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`
                    })
                }
                return filtered;
            }}
            freeSolo
            id="drink-search-box"
            sx={{mb: 4}}
            options={listItems}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.label;
            }}
            renderInput={(params) => <TextField {...params} label={searchCat + " Search"} />}
        />
    )
 };

export default DrinkList;